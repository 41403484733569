import React, { useEffect } from "react";
import { gsap } from "gsap";
import { makeStyles } from "@material-ui/core/styles";

//Components
import Layout from "../components/layout";
import Loading from "../components/loading";

const useStyles = makeStyles((theme) => ({
  gridDiv: {
    paddingRight: "100px",
    [theme.breakpoints.down("xs")]: {
      paddingRight: "20px",
    },

  },
  welcome: {
    transform: "translateX(-100px)",
    paddingBottom: "100px",
  },

  heading: {
    overflow: "hidden",
    position: "relative",
    height: "40px",
    justifyContent: "flex-end",
    display: "flex",
  },
  text: {
    position: "absolute",
    transform: "translateY(40px)",
    textAlign: "right",
    width: "fit-content",
  },
  highlight: {
    position: "absolute",
    transform: "translateY(40px)",
    textAlign: "right",
    width: "fit-content",
    backgroundImage: "linear-gradient( transparent 37%, #E2FF29 0% )",
    backgroundRepeat: "no-repeat",
    backgroundSize: "0% 100%",
  },
  disclaimer: {
    position: "absolute",
    bottom: "10px",
    right: "10px",
    fontSize: "12px",
  },
}));

function Home() {
  const classes = useStyles();

  return (
    <Layout
      pageTitle="SR Web Development "
      description="Sina Rosemann Web Development and Web Design"
    >
      <Loading>
        <div className={classes.gridDiv}>
          <h1 className={`${classes.welcome} font__big uppercase`}>
            Coming+soon
          </h1>
          <h3 className={`${classes.heading}`}>
            <span
              className={`${classes.highlight} home__intro home__highlight`}
            >
              Contact
            </span>
          </h3>
          <h3 className={classes.heading}>
            <span className={`${classes.text} home__intro `}>
              Sina Rosemann Web Development & Web Design
            </span>
          </h3>
          <h3 className={classes.heading}>
            <span className={`${classes.text} home__intro `}>
              <a href="mailto: sinarosemann.webdesign@gmail.com">
                contact@sinarosemann.com
              </a>
            </span>
          </h3>
        </div>
        <a href="/disclaimer" className={classes.disclaimer}>
          <span className="">Disclaimer</span>
        </a>
      </Loading>
    </Layout>
  );
}

export default Home;
