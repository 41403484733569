import React from "react"
import { Helmet } from 'react-helmet'
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom"


const useStyles = makeStyles((theme) => ({
    navbar: {
      width: "100vw",
      zIndex: "10",
      "& a": {
          display: "flex",
          alignItems: "center",
          width: "fit-content",
      },
        "& hr": {
            width: "60px",
            display: "inline-flex",
            margin: "0px 30px 0px 0px",
            color: "#313131"
        },
        "& h6": {
            display: "inline-flex"
        }
    },

}))

export default  function Layout(props) {
    const classes = useStyles();


    return (
        <>
          <Helmet>
              <title>{props.pageTitle}</title>
              <meta name="description" content={props.description}></meta>
            </Helmet>

            <div className={classes.navbar}>
                  <a className={classes.logo} href="/"><hr/><h6 className="uppercase">Sina Rosemann</h6></a>
            </div>
            {props.children}

      </>
      )

}
