import React, { useEffect } from "react";
import { gsap } from "gsap";
import { makeStyles } from "@material-ui/core/styles";

//Components
import Layout from "../components/layout";
import Loading from "../components/loading";

const useStyles = makeStyles((theme) => ({
  gridDiv: {
    padding: "0px 60px",
  },
}));

function Home() {
  const classes = useStyles();

  return (
    <Layout
      pageTitle="Disclaimer"
      description="Sina Rosemann Web Development and Web Design Disclaimer"
    >
      <div className={classes.gridDiv}>
        <h3>Disclaimer for Sinarosemann.com</h3>
        <p>
          Sina Rosemann
          <br />
          Sina Rosemann Web Design
          <br />
          Karlstraße 12
          <br />
          71706 Markgroeningen
          <br />
        </p>
        <p>
          Contact: Phone: +49 (0) 151 68109752
          <br />
          E-Mail: contact@sinarosemann.com
          <br />
        </p>
        <p>EU VAT no.:&nbsp;DE314931077</p>
        <p>
          If you require any more information or have any questions about our
          site's disclaimer, please feel free to contact us by email at
          webdesign@sinarosemann.com.
        </p>
        <p>
          Disclaimers for www.sinarosemann.com: All the information on this
          website is published in good faith and for general information purpose
          only. www.sinarosemann.com does not make any warranties about the
          completeness, reliability and accuracy of this information. Any action
          you take upon the information you find on this website
          (www.sinarosemann.com), is strictly at your own risk.
          www.sinarosemann.com will not be liable for any losses and/or damages
          in connection with the use of our website.
        </p>
        <p>
          From our website, you can visit other websites by following hyperlinks
          to such external sites. While we strive to provide only quality links
          to useful and ethical websites, we have no control over the content
          and nature of these sites. These links to other websites do not imply
          a recommendation for all the content found on these sites. Site owners
          and content may change without notice and may occur before we have the
          opportunity to remove a link which may have gone 'bad'.
        </p>
        <p>
          Please be also aware that when you leave our website, other sites may
          have different privacy policies and terms which are beyond our
          control. Please be sure to check the Privacy Policies of these sites
          as well as their "Terms of Service" before engaging in any business or
          uploading any information.
        </p>
        <p>
          Consent By using our website, you hereby consent to our disclaimer and
          agree to its terms.
        </p>
        <p>
          Update This site disclaimer was last updated on: Tuesday, December
          5th, 2017 · Should we update, amend or make any changes to this
          document, those changes will be prominently posted here.
        </p>

        <br />
        <h3>Impressum</h3>
        <p>
          Angaben gemäss&nbsp;§ 5 TMG:
          <br />
          Sina Rosemann
          <br />
          Sina Rosemann Web Design
          <br />
          Karlstraße 12
          <br />
          71706 Markgroeningen
          <br />
        </p>
        <p>
          Kontakt: Telefon: +49 (0) 151 68109752
          <br />
          E-Mail: contact@sinarosemann.com
          <br />
        </p>
        <p>
          Umsatzsteuer:
          <br />
          Umsatzsteuer-Identifikationsnummer gemäß §27
          a&nbsp;Umsatzsteuergesetz:&nbsp;DE314931077
        </p>
        <p>
          Angaben zur Berufshaftpflichtversicherung
          <br />
          Name und Sitz des Versicherers: Markel International Insurance Company
          Ltd., Niederlassung für Deutschland Sophienstr. 26 80333 München
          <br />
          Geltungsraum der Versicherung: Weltweit
        </p>
        <p>
          Streitschlichtung Wir sind nicht bereit oder verpflichtet, an
          Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
          teilzunehmen.
        </p>
        <p>
          Haftung für Inhalte Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG
          für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
          verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter
          jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
          Informationen zu überwachen oder nach Umständen zu forschen, die auf
          eine rechtswidrige Tätigkeit hinweisen.
        </p>
        <p>
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
          Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
          Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
          von entsprechenden Rechtsverletzungen werden wir diese Inhalte
          umgehend entfernen.
        </p>
        <p>
          Haftung für Links Unser Angebot enthält Links zu externen Webseiten
          Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können
          wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die
          Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder
          Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum
          Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
          Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
          erkennbar.
        </p>
        <p>
          Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
          ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
          Bekanntwerden von Rechtsverletzungen werden wir derartige Links
          umgehend entfernen.
        </p>
        <p>
          Urheberrecht Die durch die Seitenbetreiber erstellten Inhalte und
          Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die
          Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung
          außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen
          Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien
          dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch
          gestattet.
        </p>
        <p>
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
          wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
          Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
          eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
          werden wir derartige Inhalte umgehend entfernen.
        </p>
        <p>Quelle: eRecht24</p>
      </div>
    </Layout>
  );
}

export default Home;
