import React from "react";
import { gsap } from "gsap";

export default class Loading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      done: undefined,
      text: "Coming+Soon",
    };
  }

  componentDidMount() {
    let tl = gsap.timeline();
    let loader = { value: 0 };
    let element = document.getElementById("loading__counter");

    tl.from(".loading__header", {
      duration: 1.5,
      opacity: 0,
      x: 100,
      filter: "blur(10px)",
      stagger: 0.15,
    })
      .to(
        loader,
        {
          duration: 2.5,
          value: 100,
          ease: "linear",
          onUpdate: function () {
            element.innerHTML = loader.value.toFixed(0);
          },
        },
        "<"
      )
      .to(".loading__counter", 2.5, { x: "70vw" }, "<")
      .to(".loading__counter", 0.5, { opacity: "0" })
      .to(".loading__header", { duration: 1.1, x: -100, stagger: 0.05 }, "1.6");

    setTimeout(() => {
      fetch("https://jsonplaceholder.typicode.com/posts")
        .then((json) => this.setState({ done: true }))
        .then(() => {
          let tl = gsap.timeline();
          tl.to(".home__intro", { y: 0, stagger: 0.2 }).to(".home__highlight", {
            backgroundSize: "100% 100%"
          });
        });
    }, 3200);
  }
  render() {
    return (
      <div>
        {!this.state.done ? (
          <>
            {this.state.text.split("").map((text, index) => (
              <h1
                className="font__big uppercase loading__header inlineflex"
                key={text}
              >
                {text}
              </h1>
            ))}
            <h2 id="loading__counter" className="loading__counter">
              0
            </h2>
          </>
        ) : (
          this.props.children
        )}
      </div>
    );
  }
}
