import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

// Pages
import Home from "./pages/home";
import Disclaimer from "./pages/disclaimer"

function App() {
  return (
    <Router>
      <div className="container">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/disclaimer" component={Disclaimer} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
